<template>
  <div class="help-center min-main">
    <Header></Header>
    <div class="banner min-main">
      <img
        class="banner-bg min-main"
        src="@/assets/img/pc/helpCenter/banner.png"
      >
      <div class="banner-title">
        <h2>帮助中心</h2>
        <div></div>
      </div>
    </div>
    <main class="help-center-content main-content">
      <div class="help-center-content-indicator">
        <div
          @click="handleMenuClick(item)"
          v-for="(item,index) in menuList"
          :key="index"
          :class="{'indicator-select':selectQuestion==item}"
        >
          <p>{{item.menu}} </p><img :src="item ===selectQuestion?require('@/assets/img/pc/helpCenter/arrow-selected.png'):require('@/assets/img/pc/helpCenter/arrow.png')">
        </div>
      </div>
      <div class="help-center-content-line"></div>
      <div class="help-center-content-faq">
        <ul
          v-for="item,index in selectQuestion.content"
          :key="index"
        >
          <li>
            <h2 class="help-center-content-faq-title">{{item.question}}</h2>
            <p class="help-center-content-faq-content">{{item.answer}}</p>
          </li>
        </ul>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'

export default {
  data () {
    return {
      selectQuestion: {},
      menuList: [{
        menu: '关于易借',
        content: [{
          question: '什么是易借速贷？',
          answer: '易借速贷是一个面向广大用户群体的金融科技服务平台，平台上所展示的所有金融产品均来自于正规金融机构。'
        }, {
          question: '易借速贷有哪些特色？',
          answer: '易借平台通过人工智能和大数据风控技术为用户带来方便、安心的互联网消费信贷服务，具有申请易、放款快、额度高等特色。'
        }, {
          question: '使用易借平台申请是否安全？',
          answer: '用户在易借平台首次申请借款时需通过身份证验证，人脸识别，手机实名，短信验证，且款项只能放款到用户本人名下的银行卡，请放心使用。'
        }]
      }, {
        menu: '申请问题',
        content: [{
          question: '申请条件',
          answer: '用户须是年龄在23-54周岁（年龄限制或有调整，具体以实际规则为准）的自然人，且非在校学生。申请过程中需要提供您的二代身份证，本人借记卡。'
        }, {
          question: '申请流程',
          answer: '当您用手机打开易借速贷APP后，点击领取额度→填写资料→完成身份验证→获得额度。'
        }, {
          question: '审核时间',
          answer: '易借速贷采用实时审核，最快10秒钟审核，最高20万额度。'
        }, {
          question: '为什么授信失败？',
          answer: '额度申请的综合评分是系统判定的，有严密的风险控制等诸多因素。'
        }, {
          question: '填写信息注意事项',
          answer: '申请支持借记卡（建议1类卡）。由于部分网络问题验证码会发送较慢，请不要频繁点击“发送验证码”，在获得验证码后请在30分钟内完成申请。本人身份信息需为二代身份证信息，不能使用临时身份证、过期身份证、一代身份证进行申请。请填写紧急联系人的真实姓名，以保证顺利通过审核。'
        }, {
          question: '关于征信',
          answer: '“易借速贷“已与锡商银行、大兴安岭银行、马上消费金融、万方小贷等金融机构展开合作，并已接入银行征信系统，所有用户的逾期情况都会在个人征信记录里中体现，同时与多家第三方征信机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，会在行业系统中披露失信信息。'
        }]
      }, {
        menu: '额度问题',
        content: [{
          question: '额度是如何给出的?',
          answer: '易借速贷平台额度是由系统按照多维度的评估标准，综合评估后自动给出的，可用额度以页面显示为准。'
        }, {
          question: '额度会被取消吗?',
          answer: '系统会不定期的对用户进行综合评估，根据用户各类行为调整借款额度。如您经常发生逾期或者有其他信用不良的情况发生，我们可能取消或降低您的额度，请珍惜您的信用记录。'
        }]
      }, {
        menu: '借款问题',
        content: [{
          question: '易借速贷的借款流程是什么?',
          answer: '打开易借速贷App后，点击领取额度→填写资料→完成身份验证→获得额度→申请借款→资金到账。最快10秒钟到账。'
        }, {
          question: '易借速贷用户验证码异常怎么办？',
          answer: '在易借平台发生借款时验证码接收异常可能是多种因素导致的。建议确认以下情况：如果有安装第三方通讯类或安全管理类软件（如360手机卫士），建议更改软件设置。  拔出SIM卡，安装到其他的手机端做下重新开关机处理，然后再换回到原手机端重试。若排除以上情况后接收验证码仍异常，请联系平台客服。'
        }, {
          question: '易借速贷借款利率是多少?',
          answer: '易借速贷平台用户借款费率是系统根据用户的个人信息综合评估判定的，具体以额度申请成功后App首页显示为准。保持良好的借款、还款习惯有助于获得更低的借款费率。'
        }, {
          question: '用户在易借速贷平台上费率变化了，尚未结清的借款费率会有变化吗？',
          answer: '用户在易借速贷平台上申请借款的费率变化仅针对变化后新发生的借款，在费率变化前已有的借款仍按原政策执行。'
        }, {
          question: '用户从易借速贷平台获得的借款去向？',
          answer: '为保证安全，借款将发放至用户第一次绑定的用户本人银行卡上。'
        }, {
          question: '用户在易借速贷平台申请借款失败怎么办？',
          answer: '每次操作易借平台借款时，系统都会对用户的信息进行综合评估。如果无法借款，说明暂时未能通过系统综合评估，建议保持良好的借款、消费习惯，不要发生超还款能力的借款、逾期或其他违规使用的情况；同时，若用户15天内未借款，系统会定期对用户的借款资质进行复审。'
        }, {
          question: '易借速贷平台申请的借款可以借多久？',
          answer: '用户可选择进行分期还款，最低6期，最高12期。'
        }, {
          question: '借款有次数限制吗？',
          answer: '用户在借款额度和授信期限内可循环无限次使用，但再次申请借款前须结清已有的借款。'
        }, {
          question: '为什么我的借款显示待放款状态？',
          answer: '用户申请的“易借速贷”借款已审核通过，但由于人气火爆，放款时间会根据实际情况有所延迟，需要用户于预计放款日上午10:00尽早登录APP确认放款信息，先到先得。'
        }]
      }, {
        menu: '还款问题',
        content: [{
          question: '在哪里进行还款?',
          answer: '用户可登录易借速贷产品【首页】-点击【去还钱】-跳转至【未还账单】进行还款。'
        }, {
          question: '可以分期还款么?',
          answer: '借款后系统已根据用户综合情况给出不同的分期数，具体以借款页面显示期数为准。'
        }, {
          question: '如果没按时还款，会有什么影响?',
          answer: '用户应于还款日起按时足额还款，超期还款的即视为逾期，逾期将收取罚息。同时，您的逾期记录将被上报人行征信，将影响您的信用记录，珍爱信用，请按时还款。'
        }, {
          question: '还款有时间限制吗？',
          answer: '不排除存在银行结算系统维护或升级、网络故障或维护等情况影响还款，建议用户于22:00前完成还款。如遇还款问题，请及时在21:00前联系客服处理。'
        }, {
          question: '还款时间可以修改吗？',
          answer: '还款日由系统根据用户的借款情况自行设定，暂不支持延期或修改。用户须按时还款，避免因借款逾期产生不利影响。'
        }, {
          question: '是否可以提前还款?',
          answer: '除借款当天，您可以发起整笔提前结清或当月提前还款，具体以页面展示为准。'
        }, {
          question: '整笔提前结清利息如何计算?',
          answer: '整笔提前结清时，系统将自动计算您的借款截止到当天的利息。利息按实际借款天数计算，具体以页面为准。'
        }, {
          question: '什么是自动还款?',
          answer: '自动还款是指在还款当日自动从绑定银行卡里扣除当期应还金额。自动还款可以帮助用户避免逾期，需用户在还款当日前确保还款银行卡余额充足。另外，如遇银行卡状态异常、银行卡限额问题等特殊情况导致自动还款失败，需请用户自行前往易借速贷平台手动操作还款。'
        }]
      }, {
        menu: '更多问题',
        content: [{
          question: '更多问题',
          answer: '请您拨打全国统一客服电话：400-777-8802进行咨询，客服工作时间为每日9:00至21:00。'
        }]
      }]
    }
  },
  watch: {},
  mounted () {
    // this.handleQuestion('关于易借')
    this.handleMenuClick(this.menuList[0])
  },
  methods: {
    handleMenuClick (item) {
      this.selectQuestion = item
    },
    //请求faq列表
    handleFaqReq () {
      var reqParams = {
        id: 2
      };
      apiGetFaqList(reqParams).then(res => {
        console.log(res)
      })
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less">
.help-center {
  .banner {
    overflow: hidden;
    position: relative;
    .banner-bg {
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &-title {
      width: 1300px;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      h2 {
        font-size: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 45px;
        letter-spacing: 2px;
      }
      div {
        margin-top: 20px;
        width: 60px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: row;
    border: 1px solid #ebebeb;
    margin-top: 50px;
    margin-bottom: 100px;
    min-height: 611px;
    &-indicator {
      display: flex;
      width: 220px;
      flex-direction: column;
      div {
        height: 69px;
        padding-left: 40px;
        font-size: 18px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 69px;
        border-bottom: 1px solid #ebebeb;
        font-weight: 400;
        display: flex;
        align-items: center;
        position: relative;
      }
      img {
        width: 15px;
        height: 20px;
        position: absolute;
        right: 40px;
      }
    }

    &-line {
      width: 1px;
      transform: scaleX(0.5);
      background-color: #ebebeb;
    }

    &-faq {
      flex: 1;
      padding-top: 62px;
      padding-right: 90px;
      padding-left: 50px;
      &-title {
        font-size: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #1e1f20;
        line-height: 26px;
      }

      &-content {
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 21px;
        margin-top: 13px;
        margin-bottom: 42px;
      }
    }
  }
  .indicator-select {
    color: #f2aa30;
  }
}
</style>

